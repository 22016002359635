/**
 * Linkify Service
 *
 * - Library: anchorme
 *
 * For converting text message to text message Display
 *
 * We need to display all original Text as pure string (including HTML tags (valid or invalid)).
 * From the original string, we try to "linkify" all existing URLs or links.
 * The library will not handle HTML tags so we actually need to handle it manually instead of straight up using the library for whole string.
 *
 * Full imeplementation of chatroom text message:
 * 1. Replace all htmlAttrs with a -TEMP postfix so that the attribute will not be skipped by the library.
 * 2. Find all links of the temp text using library (with {list: true}, which will return an array of links object)
 * 3. From the urls array, we loop through and add all original text (not link) as a string message part after reverse-replaced the htmlAttrs.
 * 4. For the url itself, we get a <a> tag using the library, and add it as a non-string message part.
 *
 * Created by Shan - 2018-05-09
 *
 */

import { Injectable } from '@angular/core';

import anchorme from 'anchorme';
import * as _ from 'lodash';
import { MessageMention } from '../../models/message';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { strictEqual } from 'assert';
import { result } from 'lodash';


export const htmlAttrs: Array<string> = ['src=', 'data=', 'href=', 'cite=', 'formaction=', 'icon=', 'manifest=', 'poster=', 'codebase=', 'background=', 'profile=', 'usemap='];

/**
 * As we need to display HTML tags as normal string, but <a href></a> need to be a real HTML element, we need to separate the display of normal string and HTML
 *
 * @export
 * @class TextMessagePart
 */
export class TextMessagePart {
  isStr: boolean;
  text: any;
  isTranslateString?: boolean;
  isMention?: boolean;
  mentionUserId?: string;
  isHashtag?: boolean;

  constructor(isStr: boolean, text: any, isTranslateString?: boolean, isMention?: boolean, isHashtag?: boolean, mentionUserId?: string) {
    this.isStr = isStr;
    this.text = text;
    this.isTranslateString = isTranslateString;
    this.isMention = isMention;
    this.mentionUserId = mentionUserId;
    this.isHashtag = isHashtag;
  }
}

@Injectable()
export class LinkifyService {

  tempPostfix = '-TEMP';

  // tslint:disable-next-line:max-line-length
  hashtagRegEx = /#[^\r\n\t\f\v(#) \u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]+/g; ///#(\w*[^\s(_).*(#)])/g; // /#([^\s]+)/;

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  /**
   * Original pipe transform function
   * (not using)
   *
   * @param {string} str - original string
   * @returns {*} - linkified string
   * @memberof LinkifyService
   */
  transform(str: string): any {
    return str ? anchorme({
      input: str,
      options: {
        protocol: 'https://',
        attributes: {
          name: 'target',
          value: '_blank'
        }
      }
    }) : str;
  }

  /**
   * Get <a> string of the target url
   * - use library
   *
   * @param {string} url - target url
   * @returns {string} - <a> tag of url
   * @memberof LinkifyService
   */
  getATagUrl(url: string): string {
    return anchorme({
      input: url,
      options: {
        protocol: 'https://',
        attributes: {
          name: 'target',
          value: '_blank'
        }
      }
    });
  }

  /**
   * Get a list of url inside text
   * - use library
   *
   * @param {string} text - text containing urls
   * @returns {any[]} - url object array
   * @memberof LinkifyService
   */
  listUrls(text: string): any[] {
    const list = anchorme.list(text);
    return list;
  }

  /**
   * Get the temp value of html attr (for replacing)
   *
   * @param {string} attr - html attr
   * @returns {string} - html attr + temp postfix
   * @memberof LinkifyService
   */
  getTempValForAttr(attr: string): string {
    return attr + this.tempPostfix;
  }

  /**
   * Replace all existences of htmlAttrs by its corresponding temp value
   * - use RegExp
   *
   * @param {string} text - text to be transformed
   * @returns {string} - transformed string
   * @memberof LinkifyService
   */
  tempReplaceAllHtmlAttr(text: string): string {
    _.each(htmlAttrs, (attr) => {
      const pattern = new RegExp('(' + attr + ')', 'g');
      text = text.replace(pattern, this.getTempValForAttr(attr));
    });
    return text;
  }

  /**
   * Replace all existences of temp htmlAttrs by its original htmlAttr
   *
   * @param {string} text - text to be recovered
   * @returns {string} - recovered text
   * @memberof LinkifyService
   */
  tempReverseAllHtmlAttr(text: string): string {
    _.each(htmlAttrs, (attr) => {
      text = text.replace(new RegExp('(' + this.getTempValForAttr(attr) + ')', 'g'), attr);
    });
    return text;
  }

  /**
   * Get a new TextMessagePart
   *
   * @param {boolean} isStr - is the content normal string?
   * @param {string} text - content
   * @returns {TextMessagePart} - new message part object
   * @memberof LinkifyService
   */
  // tslint:disable-next-line:max-line-length
  getMessagePart(isStr: boolean, text: string, isNoNeedCheckMentionHashtag?: boolean, mentionReplacer?: MessageMention[], segmentStartIndex?: number, segmentEndIndex?: number): TextMessagePart[] {

    if (isNoNeedCheckMentionHashtag) {
      return [new TextMessagePart(isStr, text)];
    }

    let allToReplaceMapping: {index: number, type: string, text: string, mappedText: string, userId?: string}[] = [];
    const cloneText = _.clone(text);
    // find all mention
    if (mentionReplacer) {
      let pushedMappingIndex = []
      // console.warn(mentionReplacer);
      mentionReplacer.sort((a, b) => {
        return a.location - b.location;
      });
      _.each(mentionReplacer, (mention) => {
        if (text.indexOf(mention.name) !== -1) {
          if (mention.location >= segmentStartIndex && mention.location < segmentEndIndex) {
            if (_.indexOf(pushedMappingIndex, mention.location) !== -1) {
              return
            }

            allToReplaceMapping.push({
              index: mention.location,
              type: '@',
              text: '@' + mention.name,
              mappedText: '@' + mention.name,
              userId: mention.user_id
            });
            pushedMappingIndex.push(mention.location);
          }
        }
      });
    }
    // find all hashtag
    if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_ENABLE_SEARCH) {
      let result;
      while (( result = this.hashtagRegEx.exec(cloneText)) !== null) {
        console.log(result);
        allToReplaceMapping.push({
          index: result.index,
          type: '#',
          text: result[0],
          mappedText: result[0]
        });
      }
    }
    allToReplaceMapping = _.sortBy(allToReplaceMapping, 'index');
    // const results = [];
    let results = [];
    let textIndex = 0;
    if (allToReplaceMapping) {
      for (let i = 0; i < allToReplaceMapping.length; i++) {
        const mapping = allToReplaceMapping[i];
        const toReplace = mapping.text;
        const toReplaceIndex = text.indexOf(toReplace, textIndex);

        const prevText = text.substr(textIndex, toReplaceIndex - textIndex);
        if (prevText.length > 0) {
          if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_ENABLE_MARKDOWN_INPUT) {
            const allMarkdownFragments = this.findAllMarkDownMatches(prevText);
            if (allMarkdownFragments.length) {
              const markdownTextParts = this.getMarkdownMsgPart(allMarkdownFragments, prevText);
              // console.log('markdownTextParts', markdownTextParts);
              results = _.union(results, markdownTextParts);
            } else {
              results.push(new TextMessagePart(isStr, prevText));
            }

          } else {
            results.push(new TextMessagePart(isStr, prevText));
          }

          // results.push(new TextMessagePart(isStr, prevText));
        }

        // add mention / hashtag
        results.push(new TextMessagePart(isStr, toReplace, false, mapping.type === '@', mapping.type === '#', mapping.userId));

        // update textIndex pointer
        textIndex = toReplaceIndex + toReplace.length;
      }
    }
    // add remaining text
    // const remainingText = text.substr(textIndex);
    let remainingText = text.substr(textIndex);
    // console.log('remainingText', remainingText);
    if (remainingText.length > 0) {
      if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_ENABLE_MARKDOWN_INPUT) {
        const allMarkdownFragments = this.findAllMarkDownMatches(remainingText);
        if (allMarkdownFragments.length) {
  
          const markdownTextParts = this.getMarkdownMsgPart(allMarkdownFragments, remainingText);
          results = _.union(results, markdownTextParts);
        } else {
          results.push(new TextMessagePart(isStr, remainingText));
        }
      } else {
        results.push(new TextMessagePart(isStr, remainingText));
      }
      // const allMarkdownFragments = this.findAllMarkDownMatches(remainingText);
      // console.log('allMarkdownFragments', allMarkdownFragments);

      // if (allMarkdownFragments.length) {

      //   const markdownTextParts = this.getMarkdownMsgPart(allMarkdownFragments, remainingText);
      //   // console.log('markdownTextParts', markdownTextParts);
      //   results = _.union(results, markdownTextParts);
      // } else {
      //   results.push(new TextMessagePart(isStr, remainingText));
      // }
    }

    return results;
  }

  getMarkdownMsgPart(allMarkdownFragments: string[], remainingText: string): TextMessagePart[] {
    const results = [];

    let remainingTextIndex = 0;
    for (let i = 0; i < allMarkdownFragments.length; i++) {
      const markdown = allMarkdownFragments[i];
      const markdownIndex = remainingText.indexOf(markdown, remainingTextIndex);
      const prevText = remainingText.substr(remainingTextIndex, markdownIndex - remainingTextIndex);

      if (prevText.length > 0) {
        results.push(new TextMessagePart(true, prevText));
      }
      
      const mdText = this.parseMarkdownToHtmlContent(markdown);
      results.push(new TextMessagePart(false, mdText)); // isStr: false => is HTML content

      remainingTextIndex = markdownIndex + markdown.length;
    }

    const leftText = remainingText.substr(remainingTextIndex);
    // console.log('leftText', leftText);
    if (leftText.length > 0) {
      results.push(new TextMessagePart(true, leftText));
    }

    return results;
  }

  findAllMarkDownMatches(text: string): string[] {
    // const strikethroughRegex = /\~{1}([^\s][^\~]*[^\s]|[^\s]{1})\~{1}/gm;
    // const boldRegex = /\*{1}([^\s][^\*]*[^\s]|[^\s]{1})\*{1}/gm;
    // const underlineRegex = /\_{1}([^\s][^\_]*[^\s]|[^\s]{1})\_{1}/gm;
    // const combinedRegex = /(\~{1}([^\s][^\~]*[^\s]|[^\s]{1})\~{1}|\*{1}([^\s][^\*]*[^\s]|[^\s]{1})\*{1}|\_{1}([^\s][^\_]*[^\s]|[^\s]{1})\_{1})/gm;
    // const matches = text.match(combinedRegex) || [];

    
    // const combinedRegex = /(\~{1}([^\s][^\~]*[^\s]|[^\s]{1})\~{1}|\*{1}([^\s][^\*]*[^\s]|[^\s]{1})\*{1}|\_{1}([^\s][^\_]*[^\s]|[^\s]{1})\_{1})/gm;
    const combinedRegex = /(?<!\S)(\~{1}([^\s][^\~]*[^\s]|[^\s]{1})\~{1}|\*{1}([^\s][^\*]*[^\s]|[^\s]{1})\*{1}|\_{1}([^\s][^\_]*[^\s]|[^\s]{1})\_{1})(?!\S)/gm;
    const matches = text.match(combinedRegex);

    return matches?.length > 0 ? matches : [];
  }

  parseMarkdownToHtmlContent(mdText: string): string {
    return (
      mdText
        .replace(/\~{1}([^\s][^\~\n]*[^\s]|[^\s]{1})\~{1}/g, '<del>$1</del>') // strikethrough
        .replace(/\*{1}([^\s][^\*\n]*[^\s]|[^\s]{1})\*{1}/g, '<strong class="strong-content">$1</strong>') // bold
        .replace(/\_{1}([^\s][^\_\n]*[^\s]|[^\s]{1})\_{1}/g, '<em>$1</em>') // italic
        // .replace(/(?<!\S)\~{1}([^\s][^\~]*[^\s]|[^\s]{1})\~{1}(?!\S)/gm, '<del>$1</del>') // strikethrough
        // .replace(/(?<!\S)\*{1}([^\s][^\*]*[^\s]|[^\s]{1})\*{1}(?!\S)/gm, '<strong>$1</strong>') // bold
        // .replace(/(?<!\S)\_{1}([^\s][^\_]*[^\s]|[^\s]{1})\_{1}(?!\S)/gm, '<em>$1</em></em>') // italic
    );
  }

  /**
   * Parse the text message for displaying
   *
   * For full implementation logic, see the top comment of this file.
   *
   * @param {string} text - original text message
   * @returns {TextMessagePart[]} - Parsed message part array
   * @memberof LinkifyService
   */
  parseTextMessageForDisplay(text: string, mentionReplacer?: MessageMention[]): TextMessagePart[] {
    // replace all html attr first
    const temp = this.tempReplaceAllHtmlAttr(text);

    let parsedArr: TextMessagePart[] = [];

    // Get all urls in the text message
    const allUrls = this.listUrls(temp);

    if (!allUrls || allUrls.length === 0) {
      // No url, just string! add the original text
      parsedArr = _.union(parsedArr, this.getMessagePart(true, text, false, mentionReplacer, 0, temp.length));
    } else {
      let textIndex = 0;
      for (let i = 0; i < allUrls.length; i++) {
        const url = allUrls[i].string;
        const urlIndex = temp.indexOf(url, textIndex);

        const prevText = temp.substr(textIndex, urlIndex - textIndex);
        if (prevText.length > 0) {
          // add prev text as string
          parsedArr = _.union(parsedArr, this.getMessagePart(true, this.tempReverseAllHtmlAttr(prevText), false, mentionReplacer, textIndex, urlIndex));
        }

        // Add url!
        parsedArr = _.union(parsedArr, this.getMessagePart(false, this.getATagUrl(url), true));

        // update textIndex pointer
        textIndex = urlIndex + url.length;
      }
      // Add remaining text
      const reaminingText = temp.substr(textIndex);
      if (reaminingText.length > 0) {
        // add as string
        parsedArr = _.union(parsedArr, this.getMessagePart(true, this.tempReverseAllHtmlAttr(reaminingText), false, mentionReplacer, textIndex, temp.length));
      }
    }

    return parsedArr;
  }

  // trans string message to string array divided by substring(keyword) fragment 
  getMessageFragments(messageContent: string, keyword: string): string[] {
    const result = [];
    let startIndex = 0;
    let foundIndex
  
    // while ((foundIndex = messageContent.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase(), startIndex)) !== -1) {

    // }

    do {
      foundIndex = messageContent.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase(), startIndex)

      if (foundIndex === -1) {
        break;
      }

      if (foundIndex > startIndex) {
        result.push({ text: messageContent.substring(startIndex, foundIndex), isKeyword: false });
      }

      let nextStartIndex = foundIndex + keyword.length;
      result.push({ text: messageContent.substring(foundIndex, nextStartIndex), isKeyword: true });
      startIndex = nextStartIndex;
      
    } while (true);
  
    if (startIndex < messageContent.length) {
      result.push({ text: messageContent.substring(startIndex), isKeyword: false });
    }
  
    return result;
  }

}
